<template>
  <div class="home">
    <div class="home1">
      <div class="swiper mySwiper1">
        <div class="swiper-wrapper">
          <div class="swiper-slide">
            <img src="../assets/img/首页/banner1.png" alt="">
          </div>
          <div class="swiper-slide">
            <img src="../assets/img/首页/banner2.png" alt="">
          </div>
          <div class="swiper-slide">
            <img src="../assets/img/首页/banner3.png" alt="">
          </div>
          <div class="swiper-slide">
            <img src="../assets/img/首页/banner4.png" alt="">
          </div>
        </div>
        <div class="swiper-pagination"></div>
      </div>
    </div>

    <div class="home2">
      <div class="h2_cont">
        <div class="h2_item">
          <img src="../assets/img/首页/h21.png" alt="">
          <p>热门院校</p> 
        </div>
        <div class="h2_item">
          <img src="../assets/img/首页/h22.png" alt="">
          <p>热门专业</p>
        </div>
        <div class="h2_item">
          <img src="../assets/img/首页/h23.png" alt="">
          <p>音乐导师</p>
        </div>
        <div class="h2_item">
          <img src="../assets/img/首页/h24.png" alt="">
          <p>艺术留学</p>
        </div>
      </div>
    </div>

    <div class="h3">
      <div class="homoe_title">
        <img src="../assets/img/首页/titleicon.png" alt="">
        <p>ROSSO核心优势</p>
        <img src="../assets/img/首页/titleicon.png" alt="">
      </div>
      <div class="h3_cont">
        <div class="h3_item">
          <img src="../assets/img/首页/h31.png" alt="">
          <p>国外TOP10院校师资力量</p>
          <div>云集海内外知名音乐专家、教授，搭档顶尖音乐学院海归导师，为每一位学员提供个性化授课服务</div>
        </div>
        <div class="h3_item">
          <img src="../assets/img/首页/h32.png" alt="">
          <p>学院式课程体系</p>
          <div>以学生为中心，注重多元化互动式教学体验，以1对1、多对1的形式提前让学生感受海外大学课程</div>
        </div>
        <div class="h3_item">
          <img src="../assets/img/首页/h33.png" alt="">
          <p>院校合作资源</p>
          <div>与伯克利音乐学院、茱莉亚学院等多所海外名校建立合作，整合优质国际音乐教育资源</div>
        </div>
        <div class="h3_item">
          <img src="../assets/img/首页/h34.png" alt="">
          <p>海内外音乐实践</p>
          <div>提供专业留学策划团队，为学员打造海内外竞赛、演出、实习、大师课等多品类音乐实践项目</div>
        </div>
      </div>
    </div>
    <div class="h4">
      <div class="homoe_title">
        <img src="../assets/img/首页/titleicon.png" alt="">
        <p>音乐留学特色服务</p>
        <img src="../assets/img/首页/titleicon.png" alt="">
      </div>
      <div class="h4_title">
        音乐留学一站式规划，帮助学员收获理想OFFER
      </div>
      <div class="h4_cont">
        <div class="h4_item">
          <img src="../assets/img/首页/h41.png" alt="">
          <div>
            Interview指导
          </div>
        </div>
        <div class="h4_item">
          <img src="../assets/img/首页/h42.png" alt="">
          <div>
            夏校申请
          </div>
        </div>
        <div class="h4_item">
          <img src="../assets/img/首页/h43.png" alt="">
          <div>
            软实力提升
          </div>
        </div>
        <div class="h4_item">
          <img src="../assets/img/首页/h44.png" alt="">
          <div>
            VIP专属家长会
          </div>
        </div>
        <div class="h4_item">
          <img src="../assets/img/首页/h45.png" alt="">
          <div>
            作品集录制
          </div>
        </div>
      </div>
    </div>

    <div class="h5">
      <div class="homoe_title">
        <img src="../assets/img/首页/titleicon.png" alt="">
        <p>音乐导师</p>
        <img src="../assets/img/首页/titleicon.png" alt="">
      </div>
      <div class="h5_title">
        TOP音乐名校师资，为学员提供个性化授课服务
      </div>
      <div class="h5_toggle_box">
        <div class="h5_toggle_btn" @click="togglebtn(0)">音乐海外教授</div>
        <div class="h5_toggle_btn" @click="togglebtn(1)">音乐带头人</div>
      </div>
      <div class="h5_cont">
        <div class="swiper mySwiper2">
          <div class="swiper-wrapper">
            <div class="swiper-slide" v-for="(item, index) in musicTeacher" :key="index">
              <img :src="'http://image.rossoarts.com/' + item.avatar" alt="">
              <p class="p1"> {{ item.name }}</p>
              <p class="p2"> {{ item.school }}</p>
            </div>

          </div>
        </div>
      </div>
    </div>
    <!-- <div class="h6">
      <div class="homoe_title">
        <img src="../assets/img/首页/titleicon.png" alt="">
        <p>成功案例</p>
        <img src="../assets/img/首页/titleicon.png" alt="">
      </div>
      <div class="h6_toggle_box">
        <div class="h6_toggle_btn" @click="togglebtn2(0)">本科</div>
        <div class="h6_toggle_btn" @click="togglebtn2(1)">研究生</div>
      </div>
      <div class="h6_cont">
        <div class="swiper mySwiper2">
          <div class="swiper-wrapper">
            <div class="swiper-slide" v-for="(item, index) in musicTeacher" :key="index">
              <img :src="'http://image.rossoarts.com/' + item.avatar" alt="">
              <p class="p1"> {{ item.name }}</p>
              <p class="p2"> {{ item.school }}</p>
            </div>

          </div>
        </div>
      </div>
    </div> -->
    <div class="h7">
      <div class="homoe_title">
        <img src="../assets/img/首页/titleicon.png" alt="">
        <p>热门音乐资讯</p>
        <img src="../assets/img/首页/titleicon.png" alt="">
      </div>
      <div class="h7_title">
        全球最in音乐资讯，一手掌握
      </div>
      <div class="h7_cont">
        <div class="h7_item" v-for="(item, index) in musicNews" @click="jopInfDetail(item.id)">
          <img :src="'http://image.rossoarts.com/' + item.list_img" alt="">
          <div class="h7_right">
            <p class="p1">{{ item.title }}</p>
            <p class="p2"><img src="../assets/img/首页/biao.png" alt="">{{ item.create_time }}</p>
          </div>
        </div>
      </div>

      <a href="javascript:void(0)" onclick="_MEIQIA('showPanel',{ groupToken: 'c88f871be5ec47bd52d093a69fccf897' })">
        <div class="h7_more">
          查看更多
        </div>
      </a>
    </div>

    <!-- 最外层div -->
  </div>
</template>

<script>
import Swiper from 'swiper/bundle'
import 'swiper/css';
import "swiper/css/effect-flip";
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import axios from "axios";
// @ is an alias to /src
import HelloWorld from '@/components/HelloWorld.vue'
import '@/assets/css/home.css'
export default {
  name: 'HomeView',
  components: {
    HelloWorld
  },
  data() {
    return {
      musicTeacher: [],
      musicTeachercategory: 2,
      musicNews: []
    }
  },
  created() {
    // this.getMObanner()
    this.getMusicTeacher()
    this.getMusicNews()
  },
  methods: {
    jopInfDetail(val){
      this.$router.push(`/inforDetail?id=${val}`)
    },
    togglebtn2(val) {
      var h6_toggle_btn = document.getElementsByClassName("h6_toggle_btn")
      for (var i = 0; i < h6_toggle_btn.length; i++) {
        h6_toggle_btn[i].style.color = "#333"
        h6_toggle_btn[i].style.borderColor = "transparent"

      }
      h6_toggle_btn[val].style.color = "#2ca6e0"
      h6_toggle_btn[val].style.borderColor = "#2ca6e0"
    },
    togglebtn(val) {
      if (val == 1) {
        this.musicTeachercategory = 1
        this.getMusicTeacher()
        this.mySwiper2Init()

      } else {
        this.musicTeachercategory = 2
        this.getMusicTeacher()
        this.mySwiper2Init()
      }

      var h5_toggle_btn = document.getElementsByClassName("h5_toggle_btn")
      for (var i = 0; i < h5_toggle_btn.length; i++) {
        h5_toggle_btn[i].style.color = "#333"
        h5_toggle_btn[i].style.borderColor = "transparent"

      }
      h5_toggle_btn[val].style.color = "#2ca6e0"
      h5_toggle_btn[val].style.borderColor = "#2ca6e0"
    },
    getMusicNews() {
      axios.get(`http://public.rossoarts.com/api/v1/getMusicNews?category=0&limit=5&page=1`).then((data) => {
        // console.log(data.data.data.data);
        this.musicNews = data.data.data.data
        // this.$nextTick(() => {
        //   this.mySwiper5Init()
        // })
      })
    },
    getMusicTeacher() {
      axios.get(`http://public.rossoarts.com/api/v1/getMusicTeacher?category=${this.musicTeachercategory}&page=1&limit=9999`).then((data) => {
        // console.log(data.data.data.data);
        this.musicTeacher = data.data.data.data
        // this.$nextTick(() => {
        //   this.mySwiper5Init()
        // })
      })
    },
    getMusicTeacher() {
      axios.get(`http://public.rossoarts.com/api/v1/getMusicTeacher?category=${this.musicTeachercategory}&page=1&limit=9999`).then((data) => {
        // console.log(data.data.data.data);
        this.musicTeacher = data.data.data.data
        // this.$nextTick(() => {
        //   this.mySwiper5Init()
        // })
      })
    },
    mySwiper1Init() {
      var swiper = new Swiper(".mySwiper1", {
        clickable: true,
        loop: true,
      


      });

    },
    mySwiper2Init() {
      var swiper = new Swiper(".mySwiper2", {
        slidesPerView: 2,
        centeredSlides: true,
        spaceBetween: 30,
        grabCursor: true,
        observe: true,
        // pagination: {
        //   el: ".swiper-pagination",
        //   clickable: true,
        // },
      });
    },
  },
  mounted() {
    this.mySwiper1Init()
    this.mySwiper2Init()
  }
}
</script>
<style lang="less"></style>
